export default [
  {
    path: '/book/order',
    name: 'book-order',
    component: () => import('@/views/book/order'),
    meta: {
      title: '项目预约'
    }
  },
  {
    path: '/book/home',
    name: 'book-home',
    component: () => import('@/views/book/home'),
    meta: {
      title: '我的预约'
    }
  },
  {
    path: '/book/success',
    name: 'book-success',
    component: () => import('@/views/book/success'),
    meta: {
      title: '项目预约'
    }
  }
]
